/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { AiFillStar } from 'react-icons/ai'
import _ from 'lodash'
import { useI18n } from '@bestyled/primitives'
import Image from '../../components/Image'
import { useAppContext } from '../../utils/global-context'

export interface ILandingTestimonialsProps {
  testimonials: {
    title: string
    subTitle: string

    items: {}[]
  }
  [x: string]: any
}

export function LandingTestimonials<IArticlesBenefitsProps>({ testimonials, ...rest }) {
  const { items } = testimonials
  const theme = useTheme()
  const { t } = useI18n()
  const [appContextState, setContext] = useAppContext()
  const translatedTitle = t('testimonials.title')
  const translatedHeaderTitle = t('testimonials.headerTitle')

  const navItemTitle = testimonials.headerTitle ? translatedHeaderTitle : translatedTitle 

  function cleanup() {
    setContext((prev) => {
      delete prev.navItems?.testimonials
      return prev
    })
  }

  React.useEffect(() => {
    if (!appContextState.navItems) appContextState.navItems = {}
    if (!appContextState.navItems.testimonials || appContextState.navItems.testimonials !== navItemTitle)
      setContext((prev) => ({ ...prev, navItems: { ...prev.navItems, testimonials: navItemTitle } }))
    
    return cleanup
  }, [testimonials.title, testimonials.headerTitle])

  const { TestimonialsAvatar: testimonialsAvatar } = theme as any
  return (
    <TestimonialsSection className='container' id="testimonials-section">
      <TestimonialsHeader className='row'>
        <TestimonialsTitle className='col-12'>{translatedTitle}</TestimonialsTitle>
        <TestimonialsSubHeading className='col-12'>{t('testimonials.subTitle')}</TestimonialsSubHeading>
      </TestimonialsHeader>
      <CardRow className='row'>
        {items.map((item, index) => {
          return (
            <div key={index} className='col-4 col-sm-6'>
              <TestimonialsCard
                avatar={item.avatar ?? testimonialsAvatar}
                item={item}
                index={index}
                key={index}
              ></TestimonialsCard>
            </div>
          )
        })}
      </CardRow>
    </TestimonialsSection>
  )
}

interface TestimonialsCardProps {
  item: {
    index: number
    rating: number
    quote: string
    [x: string]: any
  }
  index: number
  avatar?: string
}

const TestimonialsCard = ({ item, index, avatar }: TestimonialsCardProps) => {
  let Icon = (key) => <AiFillStar key={key} />
  const { t } = useI18n()
  const prefix = `testimonials.items.${index}`
  const imageSource = item.avatar?.small?.fluid ?? null

  return (
    <Card item={item} index={index} key={index}>
      <CardBody>
        <CardRating>{_.times(item.rating, Icon)}</CardRating>
        <CardText>"{t(`${prefix}.quote`)}"</CardText>
        <CardAvatar className='row'>
          <div className='col-sm-2'>
            {!imageSource || _.isEmpty(imageSource) ? (
              <CardEmptyImg src={avatar} />
            ) : (
              <CardAvatarImg src={{ ...imageSource, aspectRatio: 1 }} />
            )}
          </div>
          <CardAvatarNameWrapper className='col-sm-10'>
            {item.participant_name && <CardAvatarName> - {t(`${prefix}.participant_name`)}</CardAvatarName>}
          </CardAvatarNameWrapper>
        </CardAvatar>
      </CardBody>
    </Card>
  )
}

const TestimonialsSection = styled.section`
  z-index: 5;
  padding-top: 4em;
  padding-bottom: 4em;

  * {
    z-index: 5;
  }
  .row {
    @media only screen and (min-width: 60em) {
      width: 100%;
      margin-left: 5%;
    }
  }

  .row [class^='col'] {
    @media only screen and (min-width: 60em) {
      margin: 15px 1.2% 0 !important;
    }
  }
`

const TestimonialsHeader = styled.header`
  font-family: ${(p) => p.theme.fonts.header};
  font-weight: ${(p) => p.theme.fonts.weights.header};
  line-height: 1.9;
  color: #757575;
  box-sizing: border-box;
  display: block;
  text-align: center;
  margin: 0 auto 70px;
`

const TestimonialsTitle = styled.h2`
  text-align: center;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0.5em;
  font-family: Dosis, sans-serif;
  line-height: 1.5;
  font-size: ${(p) => p.theme.fontSizes[6]}px;
  font-family: ${(p) => p.theme.fonts.header};
  color: ${(p) => p.theme.colors.primary};
  letter-spacing: 0.5px;
  font-weight: 400;
`

const TestimonialsSubHeading = styled.h4`
  font-family: ${(p) => p.theme.fonts.paragraph};

  line-height: 1.9;
  color: #757575;
  text-align: center;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 1.3em;
  font-weight: 300;
  font-size: ${(p) => p.theme.fontSizes[3]}px;
`

const CardRow = styled.div`
  line-height: 1.9;
  color: #757575;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  /* margin-right: -15px;
  margin-left: -15px;
  margin-top: -15px;
  margin-bottom: -15px; */
  flex-basis: max-content;
`

const Card = styled.div<TestimonialsCardProps>`
  font-family: ${(p) => p.theme.fonts.serif};

  font-size: ${(p) => p.theme.fontSizes[3]}px;
  font-weight: ${(p) => p.theme.fontWeights.normal};
  color: ${(p) => p.theme.colors.secondary};
  line-height: 1.9;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  border: 0;
  transition: 0.5s;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
  height: 100%;
`
const CardBody = styled.div`
  font-size: ${(p) => p.theme.fontSizes[5]}px;
  font-family: ${(p) => p.theme.fonts.serif};
  font-weight: ${(p) => p.theme.fontWeights.normal};
  line-height: 1.9;
  color: ${(p) => p.theme.colors.secondary};
  text-align: left;
  word-wrap: break-word;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-items: stretch;
  flex: 1 1 auto;
  padding: 1.25rem;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  height: 100%;
  justify-content: space-between;
`
const CardRating = styled.div`
  font-size: ${(p) => p.theme.fontSizes[5]}px;
  font-family: ${(p) => p.theme.fonts.serif};
  font-weight: ${(p) => p.theme.fontWeights.normal};
  line-height: 1.9;
  color: #ffba00;
  text-align: left;
  word-wrap: break-word;
  box-sizing: border-box;
  margin-bottom: 0.75rem !important;
  display: inline-flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
`
const CardText = styled.p`
  font-family: ${(p) => p.theme.fonts.paragraph};

  font-size: ${(p) => p.theme.fontSizes[2]}px;

  font-weight: ${(p) => p.theme.fontWeights.normal};

  line-height: 1.9;
  word-wrap: break-word;
  text-align: left !important;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 1rem;
  color: ${(p) => p.theme.colors.grey};
`
const CardAvatar = styled.div`
  font-weight: 300;
  line-height: 1.9;
  color: #757575;
  text-align: left;
  word-wrap: break-word;
  box-sizing: border-box;
  display: flex;
  align-items: center !important;
  padding-bottom: 0 !important;
`
const CardAvatarNameWrapper = styled.div`
  align-self: center;
`
const CardEmptyImg = styled.img`
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.9;
  color: #757575;
  text-align: left;
  word-wrap: break-word;
  box-sizing: border-box;
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  margin-right: 0.75rem !important;
  border-radius: 10rem;
  width: 34px;
  height: 34px;
`
const CardAvatarImg = styled(Image)`
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.9;
  color: #757575;
  text-align: left;
  word-wrap: break-word;
  box-sizing: border-box;
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  margin-right: 0.75rem !important;
  border-radius: 10rem;
  width: 34px;
  height: 34px;
`
const CardAvatarName = styled.p``
